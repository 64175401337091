import { Injectable } from '@angular/core';
import {
  Router,
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
} from '@angular/router';
import { AuthService } from '../../modules/service/auth-service';
import { ProfileService } from '../../modules/service/profile-service';
import { Location } from '@angular/common';


@Injectable({ providedIn: 'root' })
export class UnauthGuard implements CanActivate {
  constructor(
    private router: Router,
    private authenticationService: AuthService,private profile:ProfileService,private location: Location
  ) {}

  // canActivate
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    let token = this.authenticationService.isSessionActive();
    // let role = this.home.profileData?.role
    if (state.url === '/email-verified') {
      return true;
    }
    if (state.url === '/email-verification-failed') {
      return true;
    }
    if (state.url === '/email-already-verified') {
      return true;
    }
    if (state.url.startsWith('/confirm-payment')) {
      
      return true;
    }
    if (token) {
      let role = localStorage.getItem('role');
      if(role == '1'){
        this.router.navigate(['/admin']);
        return false;
      } else {
        this.router.navigate(['/user']);
        return false;
      }
        // this.location.back();
      } else {
        // this.router.navigate(['/']);
        return true;
      }
    // if (role.roleId == 1 || role.roleName == 'admin') {
  
    //   console.log(role)
    //   return true;
    // } else {
    //   this.router.navigate(['/home/client-otp-verify']);
    //   return false;
    // }
  }
}
