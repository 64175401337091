import {Injectable} from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor, HttpErrorResponse, HttpClient
} from '@angular/common/http';
import {catchError, Observable, switchMap, throwError} from 'rxjs';
import { environment } from '../../../environment/environment';
import { AuthService } from '../../modules/service/auth-service';
import { Router } from '@angular/router';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
  static accessToken = '';
  static refreshToken: any;

  constructor(
    private http: HttpClient, 
    private authenticationService: AuthService, 
    private router: Router
  ) { }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    JwtInterceptor.refreshToken = localStorage.getItem('refreshToken');
    const token = this.authenticationService.isSessionActive();
    JwtInterceptor.accessToken = token ? token : '';

    // Determine if the current request is the token refresh request
    const isTokenRefreshRequest = request.url.includes('api/v1/auth/token');

    // Add the access token to headers only if this is not the token refresh request
    const req = request.clone({
      setHeaders: isTokenRefreshRequest ? {} : {
        Authorization: `Bearer ${JwtInterceptor.accessToken}`
      }
    });

    return next.handle(req).pipe(
      catchError((err: HttpErrorResponse) => {

        const rememberMe = localStorage.getItem('rememberMe');
        if(err.status === 401 && rememberMe !== '1'){
          this.authenticationService.logout();
          this.router.navigate(['/signin']);
        } else if (err.status === 401 && !isTokenRefreshRequest) {

          // Call the token refresh endpoint
          return this.http.post(`${environment.apiHost}api/v1/auth/token`, {}, {
            withCredentials: true,
            headers: {
              Authorization: `Bearer ${JwtInterceptor.refreshToken}`
            }
          }).pipe(
            switchMap((res: any) => {
              // Extract the token from the response
              JwtInterceptor.accessToken = res.token;
              localStorage.setItem('token',res.token);
              // Retry the original request with the new token
              return next.handle(request.clone({
                setHeaders: {
                  Authorization: `Bearer ${JwtInterceptor.accessToken}`
                }
              }));
            }),
            catchError((innerErr: HttpErrorResponse) => {
              // Handle the case where the token refresh fails
              return throwError(() => innerErr);
            })
          );
        } else {
          // this.toasterService.error(err.error.message, "Error!", {
          //   progressBar: true,
          //   // toastClass: "toast ngx-toastr",
          //   closeButton: true,
          //   timeOut: 3000,
          // });
        }
        return throwError(() => err);
      })
    );
  }
}
